// LINKS
.link {
  @apply text-blue-700 underline cursor-pointer #{!important};
}

.content-background {
  background: #fbfbfb;
}

h1 {
  @apply text-2xl font-bold #{!important};
}

h2 {
  @apply text-xl font-bold #{!important};
}

// BUTTONS
.button {
  @apply my-2 inline-flex justify-center items-center px-4 py-2 font-semibold content-center focus:ring-4 focus:ring-offset-0 focus:ring-gray-200 rounded-md focus:outline-none select-none transition duration-200 ease-linear;

  &-primary {
    @apply text-white bg-blue-500 border-2 border-blue-500 hover:bg-blue-600;

    &-white {
      @apply text-blue-500 hover:text-blue-600 border-2 bg-white border-white;
      &:disabled,
      &[disabled] {
        @apply bg-gray-300 hover:bg-gray-300 border-gray-300 text-white;
      }
    }

    &:disabled,
    &[disabled] {
      @apply bg-gray-300 hover:bg-gray-300 border-gray-300;
    }
  }

  &-secondary {
    @apply text-blue-500 bg-white border-2 hover:text-blue-600 border-blue-500 hover:border-blue-600 focus:border-blue-600 focus:text-blue-500;

    &-white {
      @apply text-white border-white border-2;
      &:hover {
        @apply opacity-75;
      }
    }

    &:disabled,
    &[disabled] {
      @apply text-gray-300 border-gray-300;
    }
  }

  &-danger {
    @apply text-red-500 bg-white border-2 hover:text-red-600 border-red-500 hover:border-red-600 focus:border-red-600 focus:text-red-500;

    &-white {
      @apply text-white border-white border-2;
    }

    &:disabled,
    &[disabled] {
      @apply text-gray-300 border-gray-300;
    }
  }

  &-subtle {
    @apply text-blue-500 bg-transparent border-2 border-gray-300 hover:text-blue-600 hover:border-gray-400 focus:border-gray-300 focus:text-blue-500;

    &:disabled,
    &[disabled] {
      @apply text-gray-300 border-gray-300;
    }
  }

  &-text {
    @apply text-blue-500 hover:text-blue-600 border-opacity-0 bg-white rounded-full focus:bg-gray-100 focus:ring-0;

    &:disabled,
    &[disabled] {
      @apply opacity-25;
    }
  }

  &-light {
    @apply text-white hover:bg-gray-100 hover:text-blue-600 border-white border-2;
  }

  &-small {
    @apply px-2 py-0;
  }

  &-flat {
    @apply shadow-none border-2 text-blue-500 hover:bg-gray-100 focus:bg-gray-100;
  }

  &-wave {
    @apply py-4 px-6 bg-blue-500 hover:bg-blue-600 focus:ring-blue-500 text-white w-full transition text-base font-semibold focus:outline-none border-2 border-white;
  }
}

.header {
  @apply bg-gradient-to-br from-blue-500 to-blue-600;
}

// HEADERS
.header-small {
  @apply flex items-center px-4 lg:h-24;
}

.header-profile {
  @apply flex h-64;
}

// CUSTOM CLASSES
.h-screen-mobile {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

// NAVIGATION TABS

.tab {
  @apply relative block px-2 py-2 w-full text-center text-gray-400 hover:text-gray-600 font-medium focus:outline-none;

  &.active {
    @apply text-black border-b-2 border-blue-500;
  }
}
