/* Crome */
input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  height: 10px;
  border-radius: 5px;
  background: #d9e0e9;
  // background: linear-gradient(90.28deg, #0058cc 0.25%, #2996ca 387.11%);
  // STYLE SLIDER BACKGROUND "linear-gradient" BEFORE THUMB, AND #D9E0E9; AFTER THUMB
  // ::before ::-webkit-slider-thumb {
  //   background: linear-gradient(90.28deg, #0058cc 0.25%, #2996ca 387.11%);
  // }
  // ::after {
  //   background: #d9e0e9;
  // }
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 30px;
  width: 30px;
  background: #0a75c2;
  border: 2px solid #3f93d0;
  cursor: ew-resize;
  margin-top: -10px;
  border-radius: 50%;
}

/* Fire Fox */
input[type='range']::-moz-range-thumb {
  height: 30px;
  width: 30px;
  background: #0a75c2;
  border: 2px solid #3f93d0;
  margin-top: -10px;
  border-radius: 50%;
}

input[type='range']::-moz-range-progress {
  background-color: linear-gradient(90.28deg, #0058cc 0.25%, #2996ca 387.11%);
}

input[type='range']::-moz-range-track {
  background: #d9e0e9;
  height: 10px;
  border-radius: 5px;
}

/* Internet Explorer */
input[type='range']::-ms-fill-lower {
  background-color: linear-gradient(90.28deg, #0058cc 0.25%, #2996ca 387.11%);
}

input[type='range']::-ms-fill-upper {
  background-color: #d9e0e9;
}

/* Slider progress div */
// .slider-progress {
//   top: 50px;
//   border-radius: 7px 0 0 7px;
// }

// .slider-progress::after {
//   margin-bottom: 13px;
//   margin-left: 50px;
//   width: 5px;
//   height: 0.75rem;
//   border-radius: 7px 0 0 7px;
//   background-color: #ffffff;
//   display: inline-block;
//   vertical-align: middle;
//   content: '';
// }
