@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

html {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

body {
  width: 100vw;
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100));
  overscroll-behavior: none;
}

input[type='text'] {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-wrap: anywhere;
  word-break: break-word;
}

.is-momentumScrollable {
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  @apply select-none;
}
::-moz-placeholder {
  /* Firefox 19+ */
  @apply select-none;
}
:-ms-input-placeholder {
  /* IE 10+ */
  @apply select-none;
}
:-moz-placeholder {
  /* Firefox 18- */
  @apply select-none;
}
