.profile {
  .start-col {
    @apply w-6/12 sm:w-4/12 lg:w-4/12 xl:w-3/12;
  }
  .center-col {
    @apply w-full sm:w-8/12 lg:w-8/12 xl:w-6/12 px-4;
  }
  .end-col {
    @apply w-6/12 sm:w-0 lg:w-0 xl:w-3/12;
  }
  .react-swipeable-view-container {
    @apply h-full;
  }
}
